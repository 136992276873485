import "./index.css";

const createApp = async () => {
  const { isInIFrame } = await import("helpers/isInIFrame");
  if (isInIFrame()) {
    localStorage.clear();
  }

  const { Suspense, lazy } = await import("react");
  const { render } = await import("react-dom");

  const { LoaderWithLogo } = await import("components/loaderWithLogo");

  const App = lazy(() => import("containers/app"));

  render(
    <Suspense fallback={<LoaderWithLogo />}>
      <App />
    </Suspense>,
    document.getElementById("root"),
  );

  const { default: reportWebVitals } = await import("./reportWebVitals");
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};

createApp();

// localStorage.setItem('access-token', '94cc0ac47e5084656ec83b06d36649903d19dfe0d942f84c287f8642b87227b0')